import React from "react"
import Layout from "../components/layout"
import menuDownload from "../../static/SPEISEKARTE_2023.pdf"
import pageOne from "../../static/menuImages/0.webp"

export default function Menu() {
  function importAll(r) {
    let images = {}
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item)
    })
    return images
  }
  const images = importAll(
    require.context("../../static/menuImages", false, /\.(webp)$/)
  )

  let menu = Object.keys(images)
    .slice(1)
    .map((item, i) => (
      <img
        src={images[item]}
        alt="Image of Menu"
        className="my-1"
        loading="lazy"
      ></img>
    ))

  return (
    <Layout>
      <div className="flex flex-col justify-center text-center">
        <p className="mt-5">
          Die besondere Kombination aus mediterraner Küche und griechischer
          Gastfreundschaft. „Unsere Spezialität sind traditionelle, griechische
          Speisen und landestypische Weine aus Griechenland.“
        </p>
        <h1 className="text-xl font-bold mt-10">
          Die Speisekarte ist als PDF zu downloaden.
        </h1>
        <div className="w-16 mx-auto">
          <a href={menuDownload} alt="menu download link">
            {/* <img src={menuSVG} alt="menu icon"></img> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
            >
              <title>Download</title>
              <path
                d="M336 176h40a40 40 0 0140 40v208a40 40 0 01-40 40H136a40 40 0 01-40-40V216a40 40 0 0140-40h40"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
              />
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="32"
                d="M176 272l80 80 80-80M256 48v288"
              />
            </svg>
          </a>
        </div>
        <p className="text-xs mt-2">Datei ist 1573kb.</p>
        {/* <div className="flex flex-col px-2 my-6 max-w-lg m-auto">
          <img
            src={pageOne}
            alt="Image of Menu"
            className="my-1"
            rel="preload"
          ></img>
          {menu}
        </div> */}
      </div>
    </Layout>
  )
}
